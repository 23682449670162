const data = [
  {
    id: "broadcast",
    icon: "iconsminds-megaphone",
    label: "Broadcast",
    to: `/app/broadcast`,
    access: ["Admin", "Manager", "User"],
  },
  // {
  //   id: "users",
  //   icon: "iconsminds-conference",
  //   label: "Users",
  //   to: `/app/users`,
  //   access: ["Admin"],
  // },
];
export default data;
