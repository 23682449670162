/* eslint-disable import/no-extraneous-dependencies */
import React, { Component } from "react";
import DropzoneComponent from "react-dropzone-component";
import "dropzone/dist/min/dropzone.min.css";
import { toast } from "react-toastify";

const ReactDOMServer = require("react-dom/server");

const dropzoneComponentConfig = {
  postUrl: "false",
  autoProcessQueue: false,
  uploadMultiple: false,
};
const dropzoneConfig = {
  // thumbnailHeight: 160,
  maxFilesize: 20,
  maxFiles: 1,
  previewTemplate: ReactDOMServer.renderToStaticMarkup(
    <>
      <div className="dz-preview dz-file-preview mb-3">
        <div className="d-flex flex-row ">
          <div className="p-0 position-relative">
            <div className="dz-error-mark">
              <span>
                <i />{" "}
              </span>
            </div>
            <div className="dz-success-mark">
              <span>
                <i />
              </span>
            </div>
            <div className="preview-container">
              {/*  eslint-disable-next-line jsx-a11y/alt-text */}
              <img data-dz-thumbnail className=" border-0" />
              <i className="simple-icon-doc preview-icon" />
            </div>
          </div>
        </div>
        <div className="pl-3 pt-2 pr-2 pb-1 w-100 dz-details position-relative d-flex flex-row">
          <div>
            {" "}
            <span data-dz-name />{" "}
          </div>
          <div className="text-primary text-extra-small" data-dz-size />
          <div className="dz-progress">
            <span className="dz-upload" data-dz-uploadprogress />
          </div>
          <div className="dz-error-message">
            <span data-dz-errormessage />
          </div>
        </div>
        <a href="#/" className="remove" data-dz-remove>
          {" "}
          <i className="glyph-icon simple-icon-trash" />{" "}
        </a>
      </div>
    </>
  ),
  headers: { "My-Awesome-Header": "header value" },
};

export default class DropzoneExample extends Component {
  clear() {
    this.myDropzone.removeAllFiles(true);
  }

  render() {
    if (this.props.sent) {
      this.myDropzone.removeAllFiles(true);
      this.props.setSent(false);
    }
    return (
      <DropzoneComponent
        config={dropzoneComponentConfig}
        djsConfig={dropzoneConfig}
        eventHandlers={{
          init: (dropzone) => {
            this.myDropzone = dropzone;
          },
          addedfile: (file) => {
            this.myDropzone.removeFile(true);
            this.props.setFile(file);
          },
          removedfile: (file) => {
            this.props.setUploadedFile({});
            this.props.setShowSeparateCheckbox(false);
            this.props.setValues({
              ...this.props.values,
              seperateMessage: false,
            });
          },
          maxfilesexceeded: (file) => {
            toast.error(
              `You cannot upload more than ${dropzoneConfig.maxFiles} file`
            );
          },
          sending: (file) => this.props.handleUpload(file),
          processingmultiple: () => {
            return;
          },
        }}
      />
    );
  }
}
