import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Row,
  Card,
  CardBody,
  Form,
  Col,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { toast } from "react-toastify";
import { Colxx, Separator } from "components/common/CustomBootstrap";

import AppLayout from "layout/AppLayout";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axiosClient";
import Select from "react-select";
import DropzoneExample from "containers/forms/DropzoneExample";
import { useToken } from "auth/useToken";

const Broadcast = () => {
  const initialValues = { type: "USUALLY", textUz: "", seperateMessage: false };

  const [values, setValues] = useState(initialValues);
  const [file, setFile] = useState({});
  const [uploadedfile, setUploadedFile] = useState({});
  const dropzone = useRef();
  const [services, setServices] = useState([{ label: "Test", value: "Test" }]);
  const [servicesList, setServicesList] = useState([]);
  const [sending, setSending] = useState(false);
  const [sent, setSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showSeparateCheckbox, setShowSeparateCheckbox] = useState(false);
  const [hasUzText, setHasUzText] = useState(true);
  const [hasRuText, setHasRuText] = useState(false);
  const [hasEnText, setHasEnText] = useState(false);

  const [token, setToken] = useToken();

  useEffect(() => {
    axios
      .get("/api/discover/list", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((result) => {
        setServicesList(result.data);
        let options = [];
        result.data.forEach((element) => {
          options.push({ label: element.name, value: element.txnId });
        });
        setServices(options);
        setLoading(false);
      });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (uploadedfile.url) {
      setShowSeparateCheckbox(true);
    }
    if (value === "") {
      setShowSeparateCheckbox(false);
    }
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleUpload = (e) => {
    let bodyFormData = new FormData();
    bodyFormData.append("uploadFile", file);

    axios
      .post("/api/discover/upload", bodyFormData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((result) => {
        setUploadedFile(result.data);
        if (values.textUz !== "") {
          setShowSeparateCheckbox(true);
        }
      })
      .catch((e) => {
        console.log(e);
        toast.error("Couldn't upload the file");
      });
  };

  const handleSend = async () => {
    // console.log(values);
    setSending(true);
    axios
      .post(
        "/api/discover/push-message",
        {
          description: values.textUz,
          ...values,
          ...uploadedfile,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((result) => {
        toast.success("The message has been successfully broadcasted");
        setValues(initialValues);
        setFile({});
        setUploadedFile({});
        setSending(false);
        setSent(true);
        setShowSeparateCheckbox(false);
      })
      .catch((e) => {
        console.log(e);
        toast.error("Something went wrong");
        setSending(false);
      });
  };

  const navigate = useNavigate();

  return (
    <AppLayout>
      <div className="dashboard-wrapper">
        <Row>
          <Colxx xxs="12">
            <div className="mb-2">
              <h2 className="just-al">Broadcast Form</h2>
              <div className="text-zero top-right-button-container"></div>
            </div>
            <Separator className="mb-4" />
          </Colxx>
        </Row>
        <Row>
          <Colxx xxs="12" className="mb-4">
            <Card className="mb-4 broadcard">
              <CardBody>
                <Form className="broadcast-form text-left">
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <Label>Service</Label>
                        <Select
                          className="react-select"
                          classNamePrefix="react-select"
                          name="service"
                          value={{
                            label: values.service,
                            value: values.service,
                          }}
                          onChange={(e) => {
                            setValues({
                              ...values,
                              service: e.label,
                              txnId: e.value,
                            });
                          }}
                          options={services}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="12">
                      <FormGroup>
                        <DropzoneExample
                          ref={dropzone}
                          setFile={setFile}
                          setUploadedFile={setUploadedFile}
                          setShowSeparateCheckbox={setShowSeparateCheckbox}
                          setValues={setValues}
                          values={values}
                          handleUpload={handleUpload}
                          sent={sent}
                          setSent={setSent}
                        />
                      </FormGroup>
                    </Col>
                    {showSeparateCheckbox && (
                      <Col md="12">
                        <FormGroup>
                          <span>
                            <div className="form-check-inline">
                              <Input
                                type="checkbox"
                                checked={values.seperateMessage}
                                onChange={() =>
                                  setValues({
                                    ...values,
                                    seperateMessage: !values.seperateMessage,
                                  })
                                }
                              />
                              <Label className="form-check-label">
                                Seperate Message and Media
                              </Label>
                            </div>
                          </span>
                        </FormGroup>
                      </Col>
                    )}
                    <Col md="12">
                      <FormGroup>
                        {/* <Label>
                          Text &nbsp;&nbsp;{" "}
                          <span>
                            <div className="form-check-inline">
                              <Input
                                type="checkbox"
                                checked={hasUzText}
                                onChange={() => setHasUzText(!hasUzText)}
                              />
                              <Label className="form-check-label">Uz</Label>
                            </div>
                            <div className="form-check-inline">
                              <Input
                                type="checkbox"
                                checked={hasRuText}
                                onChange={() => setHasRuText(!hasRuText)}
                              />
                              <Label className="form-check-label">Ru</Label>
                            </div>
                            <div className="form-check-inline">
                              <Input
                                type="checkbox"
                                checked={hasEnText}
                                onChange={() => setHasEnText(!hasEnText)}
                              />
                              <Label className="form-check-label">En</Label>
                            </div>
                          </span>
                        </Label> */}
                        {hasUzText && (
                          <Input
                            type="textarea"
                            name="textUz"
                            placeholder="Your text here"
                            className="broadtext"
                            value={values.textUz}
                            onChange={handleChange}
                          />
                        )}
                      </FormGroup>
                    </Col>
                    {hasRuText && (
                      <Col md="12">
                        <FormGroup>
                          <Label>Text (Ru)</Label>
                          <Input
                            type="textarea"
                            name="textRu"
                            value={values.textRu}
                            onChange={handleChange}
                          />
                        </FormGroup>
                      </Col>
                    )}
                    {hasEnText && (
                      <Col md="12">
                        <FormGroup>
                          <Label>Text (En)</Label>
                          <Input
                            type="textarea"
                            name="textEn"
                            value={values.textEn}
                            onChange={handleChange}
                          />
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                  <Button
                    color="primary"
                    className="top-right-button"
                    onClick={handleSend}
                    disabled={
                      (!uploadedfile.url && !values.textUz) ||
                      !values.txnId ||
                      sending
                    }
                  >
                    Send
                  </Button>
                </Form>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      </div>
    </AppLayout>
  );
};

export default Broadcast;
