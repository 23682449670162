import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Card, CardTitle, Label, FormGroup, Button } from "reactstrap";
import axios from "axiosClient";
import { Formik, Form, Field } from "formik";

import { Colxx } from "../../components/common/CustomBootstrap";
import UserLayout from "layout/UserLayout";
import { useToken } from "auth/useToken";

const Login = ({ history, loading, error, loginUserAction }) => {
  const navigate = useNavigate();
  const [token, setToken] = useToken();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({
    username: "",
    password: "",
    server: "",
  });

  const onUserLogin = async (e) => {
    e.preventDefault();
    if (!loading) {
      if (password === "") {
        setErrors({ ...errors, password: "Please enter your password" });
      } else if (username === "") {
        setErrors({ ...errors, username: "Please enter the username" });
      } else if (username !== "" && password !== "") {
        try {
          const response = await axios.post("/api/authenticate", {
            username,
            password,
          });
          console.log(response.data);
          setToken(response.data.id_token);
          navigate("/app");
        } catch (error) {
          // console.log(error.response);
          setErrors({ ...errors, server: error.response.data });
        }
      }
    }
  };

  return (
    <UserLayout>
      <Row className="h-100">
        <Colxx xxs="12" md="10" className="mx-auto my-auto">
          <Card className="auth-card">
            <div className="position-relative image-side ">
              <p className="text-white h2">MAGIC IS IN THE DETAILS</p>
              <p className="white mb-0">
                Please use your credentials to login.
              </p>
            </div>
            <div className="form-side">
              <CardTitle className="mb-4">Login</CardTitle>

              <Formik>
                {() => (
                  <Form
                    className="av-tooltip tooltip-label-bottom"
                    onSubmit={onUserLogin}
                  >
                    <FormGroup className="form-group has-float-label">
                      <Label>Username</Label>
                      <Field
                        className="form-control"
                        name="username"
                        value={username}
                        onChange={(e) => {
                          setUsername(e.target.value);
                          setErrors({ ...errors, username: "" });
                        }}
                      />
                      {errors.username !== "" && (
                        <div className="invalid-feedback d-block">
                          {errors.username}
                        </div>
                      )}
                    </FormGroup>
                    <FormGroup className="form-group has-float-label">
                      <Label>Password</Label>
                      <Field
                        className="form-control"
                        type="password"
                        name="password"
                        value={password}
                        onChange={(e) => {
                          setPassword(e.target.value);
                          setErrors({ ...errors, password: "" });
                        }}
                      />
                      {errors.password !== "" && (
                        <div className="invalid-feedback d-block">
                          {errors.password}
                        </div>
                      )}
                    </FormGroup>
                    <div className="d-flex justify-content-between align-items-center">
                      <p>&nbsp;</p>
                      <p>{errors.server}</p>
                      <Button
                        type="submit"
                        color="primary"
                        className={`btn-shadow btn-multiple-state ${
                          loading ? "show-spinner" : ""
                        }`}
                        size="lg"
                      >
                        <span className="spinner d-inline-block">
                          <span className="bounce1" />
                          <span className="bounce2" />
                          <span className="bounce3" />
                        </span>
                        <span className="label">Login</span>
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Card>
        </Colxx>
      </Row>
    </UserLayout>
  );
};

export default Login;
